import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  getEstatesFailure,
  getEstatesImagesFailure,
  getEstatesImagesSuccess,
  getEstatesSuccess,
  setFilter,
  setFilterSuccess
} from './estates.actions';
import { EstatesService } from './estates.service';
import { estateAction, estateImageAction } from './estate.queries';
import { filter, flatMap } from 'lodash';
import { selectFilter } from './estates.selectors';
@Injectable()
export class EstatesEffects {
  constructor(private actions$: Actions, private estatesService: EstatesService, private store: Store) {}

  setFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setFilter),
      concatMap((action) => {
        return of(
          setFilterSuccess({
            filter: action.filter
          })
        );
      })
    )
  );

  getEstates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setFilterSuccess),
      withLatestFrom(this.store.select(selectFilter)),
      exhaustMap(([, filter]) => {
        return this.estatesService
          .makeRequest(
            this.estatesService.prepareRequestObject({
              ...estateAction,
              parameters: { ...estateAction.parameters, filter }
            })
          )
          .pipe(
            map((data: any, index) => {
              const flattenIds = data.results[index].data.records.map((record) => record.id);
              const estateObjects = flatMap(data.results[index].data.records.map((record) => record.elements));
              return getEstatesSuccess({
                estateIds: flattenIds,
                estates: estateObjects
              });
            }),
            catchError((error: any) => of(getEstatesFailure({ error })))
          );
      })
    )
  );

  getEstatesImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEstatesSuccess),
      exhaustMap((action) => {
        return this.estatesService
          .makeRequest(this.estatesService.prepareRequestObject(estateImageAction, action.estateIds))
          .pipe(
            map((data: any, index) => {
              const estateImageObjects = flatMap(data.results[index].data.records.map((record) => record.elements));
              const estateObjects = [];
              action.estates.map((estate) => {
                const estateObject = {
                  ...estate,
                  images: filter(
                    estateImageObjects,
                    (estateImage) => estateImage.estateid === estate.Id.toString().replace('.', '')
                  )
                };
                estateObjects.push(estateObject);
              });

              return getEstatesImagesSuccess({
                estates: estateObjects
              });
            }),
            catchError((error: any) => of(getEstatesImagesFailure({ error })))
          );
      })
    )
  );
}
