import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { EstatesService } from '../estates.service';
import {
  currentEstateAction,
  currentEstateImageAction,
  currentEstateThumbAction,
  maklerProfileAction,
  maklerPhotoAction,
  estateRundgangAction
} from './current-estate.queries';

import {
  getCurrentEstate,
  getCurrentEstateFailure,
  getCurrentEstateImages,
  getCurrentEstateImagesFailure,
  getCurrentEstateImagesSuccess,
  getCurrentEstateMaklerPhotoFailure,
  getCurrentEstateMaklerPhotoSuccess,
  getCurrentEstateMaklerProfileFailure,
  getCurrentEstateMaklerProfileSuccess,
  getCurrentEstateRundgangLink,
  getCurrentEstateRundgangLinkSuccess,
  getCurrentEstateSuccess,
  getCurrentEstateThumbs,
  getCurrentEstateThumbsFailure,
  getCurrentEstateThumbsSuccess
} from './current-estate.actions';
import { selectResourceId } from '../estates.selectors';
import { flatMap } from 'lodash';
import { selectCurrentEstateMakler } from './current-estate.selectors';

@Injectable()
export class CurrentEstateEffects {
  constructor(private actions$: Actions, private estatesService: EstatesService, private store: Store) {}

  getCurrentEstateMaklerProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCurrentEstateSuccess),
      withLatestFrom(this.store.select(selectCurrentEstateMakler)),
      exhaustMap(([, userNr]) => {
        return this.estatesService
          .makeRequest(
            this.estatesService.prepareRequestObject({
              ...maklerProfileAction,
              parameters: { ...maklerProfileAction.parameters },
              resourceid: Number(userNr)
            })
          )
          .pipe(
            map((data: any, index) => {
              const estateMaklerProfileObject = flatMap(
                data.results[index].data.records.map((record) => record.elements)
              );

              return getCurrentEstateMaklerProfileSuccess({
                maklerProfile: estateMaklerProfileObject[0]
              });
            }),
            catchError((error: any) => of(getCurrentEstateMaklerProfileFailure({ error })))
          );
      })
    )
  );

  getCurrentEstateMaklerPhoto$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCurrentEstateSuccess),
      withLatestFrom(this.store.select(selectCurrentEstateMakler)),
      exhaustMap(([, userNr]) => {
        return this.estatesService
          .makeRequest(
            this.estatesService.prepareRequestObject({
              ...maklerPhotoAction,
              parameters: { ...maklerPhotoAction.parameters },
              resourceid: Number(userNr)
            })
          )
          .pipe(
            map((data: any, index) => {
              const estateMaklerPhotoObject = flatMap(
                data.results[index].data.records.map((record) => record.elements)
              );

              return getCurrentEstateMaklerPhotoSuccess({
                maklerPhoto: estateMaklerPhotoObject[0]
              });
            }),
            catchError((error: any) => of(getCurrentEstateMaklerPhotoFailure({ error })))
          );
      })
    )
  );

  getCurrentEstateRundgangLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCurrentEstateRundgangLink),
      withLatestFrom(this.store.select(selectResourceId)),
      exhaustMap(([, resourceId]) => {
        return this.estatesService
          .makeRequest(
            this.estatesService.prepareRequestObject(
              {
                ...estateRundgangAction,
                parameters: { ...estateRundgangAction.parameters, estateid: String(resourceId) }
              },
              [resourceId.toString().replace('.', '')]
            )
          )
          .pipe(
            map((data: any, index) => {
              const currentEstateRundgangLink = flatMap(
                data.results[index].data.records.map((record) => record.elements)
              );

              return getCurrentEstateRundgangLinkSuccess({
                currentEstateRundgangLink: currentEstateRundgangLink[0].url
              });
            }),
            catchError((error: any) => of(getCurrentEstateMaklerPhotoFailure({ error })))
          );
      })
    )
  );

  getCurrentEstate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCurrentEstate),
      withLatestFrom(this.store.select(selectResourceId)),
      filter(([, resourceId]) => !!resourceId),
      exhaustMap(([, resourceid]) => {
        return this.estatesService
          .makeRequest(
            this.estatesService.prepareRequestObject({
              ...currentEstateAction,
              parameters: { ...currentEstateAction.parameters },
              resourceid: Number(resourceid.toString().replace('.', ''))
            })
          )
          .pipe(
            map((data: any, index) => {
              const estateObject = flatMap(data.results[index].data.records.map((record) => record.elements));

              return getCurrentEstateSuccess({
                currentEstate: estateObject[0]
              });
            }),
            catchError((error: any) => of(getCurrentEstateFailure({ error })))
          );
      })
    )
  );

  getCurrentEstateImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCurrentEstateImages),
      withLatestFrom(this.store.select(selectResourceId)),
      exhaustMap(([, resourceid]) => {
        return this.estatesService
          .makeRequest(
            this.estatesService.prepareRequestObject(
              {
                ...currentEstateImageAction,

                resourceid: Number(resourceid.toString().replace('.', ''))
              },
              [resourceid.toString().replace('.', '')]
            )
          )
          .pipe(
            map((data: any, index) => {
              const estateImageObjects = flatMap(data.results[index].data.records.map((record) => record.elements));

              return getCurrentEstateImagesSuccess({
                currentEstateImages: estateImageObjects
              });
            }),
            catchError((error: any) => of(getCurrentEstateImagesFailure({ error })))
          );
      })
    )
  );

  getCurrentEstateThumbs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCurrentEstateThumbs),
      withLatestFrom(this.store.select(selectResourceId)),
      exhaustMap(([, resourceid]) => {
        return this.estatesService
          .makeRequest(
            this.estatesService.prepareRequestObject(
              {
                ...currentEstateThumbAction,

                resourceid: resourceid
              },
              [resourceid.toString().replace('.', '')]
            )
          )
          .pipe(
            map((data: any, index) => {
              const estateThumbsObjects = flatMap(data.results[index].data.records.map((record) => record.elements));

              return getCurrentEstateThumbsSuccess({
                currentEstateThumbs: estateThumbsObjects
              });
            }),
            catchError((error: any) => of(getCurrentEstateThumbsFailure({ error })))
          );
      })
    )
  );
}
