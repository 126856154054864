import { createReducer, on } from '@ngrx/store';
import { initialState } from './estates.state';
import {
  getEstates,
  getEstatesFailure,
  getEstatesImages,
  getEstatesImagesFailure,
  getEstatesImagesSuccess,
  getEstatesSuccess,
  setFilterSuccess,
  setResourceId
} from './estates.actions';

export const estatesReducer = createReducer(
  initialState,

  on(getEstates, (state) => ({ ...state, isLoading: true })),
  on(getEstatesSuccess, (state, result) => ({
    estates: result.estates,
    filter: state.filter,
    resourceid: state.resourceid,
    error: null,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false
  })),
  on(getEstatesFailure, (state) => ({
    estates: state.estates,
    filter: state.error,
    resourceid: null,
    error: state.error,
    isLoading: false,
    isLoadingFailure: true,
    isLoadingSuccess: false
  })),

  on(getEstatesImages, (state) => ({ ...state, isLoading: true })),
  on(getEstatesImagesSuccess, (state, result) => ({
    estates: result.estates,
    filter: state.filter,
    resourceid: state.resourceid,
    error: null,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false
  })),
  on(getEstatesImagesFailure, (state) => ({
    estates: state.estates,
    filter: state.error,
    resourceid: null,
    error: state.error,
    isLoading: false,
    isLoadingFailure: true,
    isLoadingSuccess: false
  })),

  on(setFilterSuccess, (state, action) => ({
    filter: action.filter,
    estates: state.estates,
    resourceid: state.resourceid,
    error: null,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false
  })),

  on(setResourceId, (state, action) => ({
    filter: state.filter,
    estates: state.estates,
    resourceid: action.resourceid,
    error: null,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false
  }))
);
