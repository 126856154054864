export const maklerRelationAction = {
  actionid: 'urn:onoffice-de-ns:smart:2.5:smartml:action:get',
  resourceid: '',
  identifier: '',
  resourcetype: 'idsfromrelation',
  hmac_version: '2',
  hmac: '',
  parameters: {
    relationtype: 'urn:onoffice-de-ns:smart:2.5:relationTypes:estate:user:officer',
    parentids: [],
    data: [],
    formatoutput: true,
    listlimit: 500,
    listoffset: 0
  }
};

export const maklerProfileAction = {
  actionid: 'urn:onoffice-de-ns:smart:2.5:smartml:action:read',
  resourceid: '35',
  identifier: '',
  resourcetype: 'user',
  hmac: '',
  hmac_version: '2',
  parameters: {
    data: ['Vorname', 'Nachname', 'email', 'Telefon'],
    listlimit: 10,
    listoffset: 0
  }
};

export const maklerPhotoAction = {
  actionid: 'urn:onoffice-de-ns:smart:2.5:smartml:action:read',
  resourceid: '',
  identifier: '',
  hmac: '',
  hmac_version: '2',
  resourcetype: 'userphoto',
  parameters: {
    listlimit: 10,
    photosAsLinks: true
  }
};

export const estateRundgangAction = {
  actionid: 'urn:onoffice-de-ns:smart:2.5:smartml:action:get',
  hmac_version: '2',
  hmac: '',
  identifier: '',
  parameters: {
    data: [],
    estateids: [],
    categories: ['Film-Link'],
    size: '1800x1600',
    language: 'DEU',
    listlimit: 500,
    listoffset: 0
  },
  resourceid: null,
  resourcetype: 'estatepictures',
  timestamp: null
};

export const currentEstateAction = {
  actionid: 'urn:onoffice-de-ns:smart:2.5:smartml:action:read',
  hmac_version: '2',
  hmac: '',
  identifier: '',
  parameters: {
    data: [
      'Id',
      'plz',
      'ort',
      'strasse',
      'hausnummer',
      'benutzer',
      'land',
      'bundesland',
      'objekttitel',
      'objekttyp',
      'objektbeschreibung',
      'objektnr_extern',
      'innen_courtage',
      'aussen_courtage',
      'ausstatt_beschr',
      'sonstige_angaben',
      'stellplatzart',
      'multiParkingLot',
      'nutzungsart',
      'lage',
      'baujahr',
      'energieausweistyp',
      'energieausweis_gueltig_bis',
      'energyClass',
      'endenergiebedarf',
      'energieverbrauchskennwert',
      'energietraeger',
      'waehrung',
      'kaufpreis',
      'kaltmiete',
      'freitext_preis',
      'hausgeld',
      'wohnflaeche',
      'nutzflaeche',
      'etagen_zahl',
      'grundstuecksflaeche',
      'anzahl_zimmer',
      'anzahl_badezimmer',
      'einliegerwohnung',
      'anzahl_schlafzimmer',
      'objektart',
      'vermarktungsart',
      'status',
      'verkauft',
      'heizungsart',
      'fahrstuhl',
      'kueche',
      'unterkellert',
      'balkon',
      'gartennutzung',
      'barrierefrei'
    ],
    formatoutput: true,
    listlimit: 500,
    listoffset: 0
  },
  resourceid: null,
  resourcetype: 'estate',
  timestamp: null
};

export const currentEstateImageAction = {
  actionid: 'urn:onoffice-de-ns:smart:2.5:smartml:action:get',
  hmac_version: '2',
  hmac: '',
  identifier: '',
  parameters: {
    data: [],
    estateids: [],
    categories: ['Titelbild'],
    size: '1800x1600',
    language: 'DEU',
    listlimit: 500,
    listoffset: 0
  },
  resourceid: null,
  resourcetype: 'estatepictures',
  timestamp: null
};

export const currentEstateThumbAction = {
  actionid: 'urn:onoffice-de-ns:smart:2.5:smartml:action:get',
  hmac_version: '2',
  hmac: '',
  identifier: '',
  parameters: {
    data: [],
    estateids: [],
    categories: ['Titelbild','Foto'],
    size: '400x300',
    language: 'DEU',
    listlimit: 500,
    listoffset: 0
  },
  resourceid: null,
  resourcetype: 'estatepictures',
  timestamp: null
};
