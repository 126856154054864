import { createReducer, on } from '@ngrx/store';

import {
  getCurrentEstate,
  getCurrentEstateFailure,
  getCurrentEstateImages,
  getCurrentEstateImagesFailure,
  getCurrentEstateImagesSuccess,
  getCurrentEstateMaklerPhotoSuccess,
  getCurrentEstateMaklerProfileSuccess,
  getCurrentEstateRundgangLinkSuccess,
  getCurrentEstateSuccess,
  getCurrentEstateThumbs,
  getCurrentEstateThumbsFailure,
  getCurrentEstateThumbsSuccess
} from './current-estate.actions';
import { initialState } from './current-estate.state';

export const currentEstateReducer = createReducer(
  initialState,

  on(getCurrentEstate, (state) => ({ ...state, isLoading: true })),
  on(getCurrentEstateSuccess, (state, result) => ({
    currentEstate: result.currentEstate,
    currentEstateImages: state.currentEstateImages,
    currentEstateThumbs: state.currentEstateThumbs,
    currentEstateMaklerProfile: state.currentEstateMaklerProfile,
    currentEstateMaklerPhoto: state.currentEstateMaklerPhoto,
    currentEstateRundgangLink:state.currentEstateRundgangLink,
    error: null,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false
  })),
  on(getCurrentEstateFailure, (state) => ({
    currentEstate: state.currentEstate,
    currentEstateImages: state.currentEstateImages,
    currentEstateThumbs: state.currentEstateThumbs,
    currentEstateMaklerProfile: state.currentEstateMaklerProfile,
    currentEstateMaklerPhoto: state.currentEstateMaklerPhoto,
    currentEstateRundgangLink:state.currentEstateRundgangLink,
    error: state.error,
    isLoading: false,
    isLoadingFailure: true,
    isLoadingSuccess: false
  })),

  on(getCurrentEstateImages, (state) => ({ ...state, isLoading: true })),
  on(getCurrentEstateImagesSuccess, (state, result) => ({
    currentEstate: state.currentEstate,
    currentEstateImages: result.currentEstateImages,
    currentEstateThumbs: state.currentEstateThumbs,
    currentEstateMaklerProfile: state.currentEstateMaklerProfile,
    currentEstateMaklerPhoto: state.currentEstateMaklerPhoto,
    currentEstateRundgangLink:state.currentEstateRundgangLink,
    error: null,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false
  })),

  on(getCurrentEstateImagesFailure, (state) => ({
    currentEstate: state.currentEstate,
    currentEstateImages: state.currentEstateImages,
    currentEstateThumbs: state.currentEstateThumbs,
    currentEstateMaklerProfile: state.currentEstateMaklerProfile,
    currentEstateMaklerPhoto: state.currentEstateMaklerPhoto,
    currentEstateRundgangLink:state.currentEstateRundgangLink,
    error: state.error,
    isLoading: false,
    isLoadingFailure: true,
    isLoadingSuccess: false
  })),

  on(getCurrentEstateThumbs, (state) => ({ ...state, isLoading: true })),
  on(getCurrentEstateThumbsSuccess, (state, result) => ({
    currentEstate: state.currentEstate,
    currentEstateImages: state.currentEstateImages,
    currentEstateThumbs: result.currentEstateThumbs,
    currentEstateMaklerProfile: state.currentEstateMaklerProfile,
    currentEstateMaklerPhoto: state.currentEstateMaklerPhoto,
    currentEstateRundgangLink:state.currentEstateRundgangLink,
    error: null,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false
  })),

  on(getCurrentEstateThumbsFailure, (state) => ({
    currentEstate: state.currentEstate,
    currentEstateImages: state.currentEstateImages,
    currentEstateThumbs: state.currentEstateThumbs,
    currentEstateMaklerProfile: state.currentEstateMaklerProfile,
    currentEstateMaklerPhoto: state.currentEstateMaklerPhoto,
    currentEstateRundgangLink:state.currentEstateRundgangLink,
    error: state.error,
    isLoading: false,
    isLoadingFailure: true,
    isLoadingSuccess: false
  })),

  on(getCurrentEstateMaklerProfileSuccess, (state, result) => ({
    currentEstate: state.currentEstate,
    currentEstateImages: state.currentEstateImages,
    currentEstateThumbs: state.currentEstateThumbs,
    currentEstateMaklerProfile: result.maklerProfile,
    currentEstateMaklerPhoto: state.currentEstateMaklerPhoto,
    currentEstateRundgangLink:state.currentEstateRundgangLink,
    error: state.error,
    isLoading: false,
    isLoadingFailure: true,
    isLoadingSuccess: false
  })),

  on(getCurrentEstateMaklerPhotoSuccess, (state, result) => ({
    currentEstate: state.currentEstate,
    currentEstateImages: state.currentEstateImages,
    currentEstateThumbs: state.currentEstateThumbs,
    currentEstateMaklerProfile: state.currentEstateMaklerProfile,
    currentEstateMaklerPhoto: result.maklerPhoto,
    currentEstateRundgangLink:state.currentEstateRundgangLink,
    error: state.error,
    isLoading: false,
    isLoadingFailure: true,
    isLoadingSuccess: false
  })),
  on(getCurrentEstateRundgangLinkSuccess, (state, result) => ({
    currentEstate: state.currentEstate,
    currentEstateImages: state.currentEstateImages,
    currentEstateThumbs: state.currentEstateThumbs,
    currentEstateMaklerProfile: state.currentEstateMaklerProfile,
    currentEstateMaklerPhoto: state.currentEstateMaklerPhoto,
    currentEstateRundgangLink:result.currentEstateRundgangLink,
    error: state.error,
    isLoading: false,
    isLoadingFailure: true,
    isLoadingSuccess: false
  }))
);
