import { Routes } from '@angular/router';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { RedirectService } from './shared/services/redirect/redirect.service';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home/.',
    pathMatch: 'full'
  },
  {
    path: 'ueberuns/.',
    loadComponent: () => import('./about-us/about-us.component').then((m) => m.AboutUsComponent)
  },
  {
    path: 'ueberuns/:id',
    loadComponent: () =>
      import('./about-us/components/personal-detail/personal-detail.component').then((m) => m.PersonalDetailComponent)
  },
  {
    path: 'ueberuns/:id/.',
    loadComponent: () =>
      import('./about-us/components/personal-detail/personal-detail.component').then((m) => m.PersonalDetailComponent)
  },
  {
    path: 'leistungen/.',
    loadChildren: () => import('./services/services.module').then((m) => m.ServicesModule)
  },
  {
    path: 'offers/.',
    loadComponent: () => import('./offers/offers-national.component').then((m) => m.OffersNationalComponent)
  },
  {
    path: 'offers/:estateId',
    loadComponent: () =>
      import('./offers/components/offers-national-detail/offers-national-detail.component').then(
        (m) => m.OffersNationalDetailComponent
      )
  },
  {
    path: 'offers/:estateId/.',
    loadComponent: () =>
      import('./offers/components/offers-national-detail/offers-national-detail.component').then(
        (m) => m.OffersNationalDetailComponent
      )
  },

  {
    path: 'kontakt/.',
    loadChildren: () => import('./contact/contact.module').then((m) => m.ContactModule)
  },
  {
    path: 'karriere/.',
    loadChildren: () => import('./career/career.module').then((m) => m.CareerModule)
  },
  {
    path: 'home/.',
    loadComponent: () => import('./home/home.component').then((m) => m.HomeComponent)
  },

  {
    path: 'service/.',
    loadComponent: () => import('./service/service-overview/service-overview.component').then((m) => m.ServiceOverviewComponent)
  },
  {
    path: 'service/tippgeber/.',
    loadComponent: () => import('./service/components/tipster/tipster.component').then((m) => m.TipsterComponent)
  },
  {
    path: 'service/suchanfrage/.',
    loadComponent: () =>
      import('./service/components/search-request/search-request.component').then((m) => m.SearchRequestComponent)
  },
  {
    path: 'service/immoaktuell/.',
    loadComponent: () => import('./service/components/guidebook/guidebook.component').then((m) => m.GuidebookComponent)
  },
  {
    path: 'service/wertgutachten/.',
    loadComponent: () =>
      import('./service/components/real-estate-evaluation/real-estate-evaluation.component').then(
        (m) => m.RealEstateEvaluationComponent
      )
  },
  {
    path: 'service/officeHours/.',
    loadComponent: () =>
      import('./service/components/real-estate-office-hours/real-estate-office-hours.component').then(
        (m) => m.RealEstateOfficeHoursComponent
      )
  },
  {
    path: 'service/veranstaltungen/.',
    loadComponent: () => import('./service/components/events/events.component').then((m) => m.EventsComponent)
  },

  {
    path: 'imprint/.',
    loadComponent: () => import('./imprint/imprint.component').then((m) => m.ImprintComponent)
  },
  {
    path: 'privacy/.',
    loadComponent: () => import('./privacy/privacy.component').then((m) => m.PrivacyComponent)
  },
  {
    path: 'externalRedirect',
    canActivate: [RedirectService],
    // We need a component here because we cannot define the route otherwise
    component: NotFoundComponent
  }
];
