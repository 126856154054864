import { EstateObject } from '../models/estate-object.model';

export interface EstatesState {
  estates: EstateObject[];
  filter: any;
  resourceid: number;
  error: string;
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
}

export class EstatesInitState implements EstatesState {
  estates: EstateObject[];
  filter: any;
  resourceid: number;
  error: string;
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
  constructor() {
    this.estates = null;
    this.filter = null;
    this.resourceid = null;
    this.error = null;
    this.isLoading = false;
    this.isLoadingSuccess = false;
    this.isLoadingFailure = false;
  }
}
export const initialState = new EstatesInitState();
