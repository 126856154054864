import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OnOfficeRequestAction } from '../../shared/services/onOffice/models/request.model';
import { environment } from '../../../environments/environment';

const CONFIG = {
  url: environment.url
};

@Injectable({
  providedIn: 'root'
})
export class EstatesService {
  timestamp = Math.round(new Date().getTime() / 1000);
  constructor(private http: HttpClient) {}


  prepareRequestObject(action: OnOfficeRequestAction, estateIds?: string[]) {
    return {
      request: {
        actions: [
          {
            ...action,
            timestamp: this.timestamp,
            parameters: {
              ...action.parameters,
              estateids: estateIds
            }
          }
        ]
      }
    };
  }

  makeRequest(estatePayload) {
    return this.http.post(CONFIG.url, estatePayload);
  }

}
